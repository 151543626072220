// Dependencies
import React from "react";
import { graphql, Link } from "gatsby";
import PropTypes from "prop-types";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
// Components
import Layout from "../components/Layout"

const ThumbnailLink = ({ to = "/", className = "block p3", image }) => {
	return (
		<Link to={to} className="block p4">
			<GatsbyImage image={image} alt={'Test'} />
			<div className="outer">
				<p className="">Pre-fabrication and innovation elevate the detail design of this new typology</p>
				<h5>Hanover Square</h5>
			</div>
		</Link>
	)
}

const Styleguide = ({ data }) => {

	const placeholder = data.contentfulSettings.defaultShareImage.gatsbyImageData;

	return (
		<Layout title="Home">
			<h1>Optimisation Study: 100 Bishopsgate</h1>

			<div className="grid-3">
				<div className="span-2">
					<div className="p2 outerx2 has-indents">
						<p>We know how to unlock value and create highly efficient, elegant and effective office buildings that attract tenants.</p>
						<p>We believe in challenging the status quo and thinking differently on every project. We also believe that it does not cost anymore in professional fees, or take any longer, to design buildings from the ‘inside-out’ as well as ‘outside-in’ during the planning and concept design stage.</p>
						<p>Our team generated hitherto unrealised value at 100 Bishopsgate and significantly reduced the construction cost; all within the constraints of the Section 73 planning process. The metrics below are a summary of the headline achievements we have realised for our client.</p>
					</div>

					<div className="p3 outerx2">
						<h3>Filter</h3>
						<p>Pre-fabrication and innovation elevate the detail design of this new typology</p>
					</div>

					<div className="p4 outerx2 has-indents has-links">
						<h4>Margherita Filpi</h4>
						<p>We know how to unlock value and create highly efficient, elegant and effective office buildings that attract tenants.</p>
						<p>We believe in challenging the status quo and thinking differently on every project. We also believe that it does not cost anymore in professional fees, or take any longer, to design buildings from the ‘inside-out’ as well as ‘outside-in’ during the planning and concept design stage.</p>
						<p>Our team generated hitherto unrealised value at 100 Bishopsgate and significantly reduced the construction cost; all within the constraints of the Section 73 planning process. The metrics below are a summary of the headline achievements we have realised for our client.</p>
						<p><a href="/">5 Mar, Dezeen, Bridget Cogley</a></p>
					</div>
				</div>
			</div>

			<div className="grid-3 outerx4">
				<ThumbnailLink to="/" image={placeholder} />
				<ThumbnailLink to="/" image={placeholder} />
				<ThumbnailLink to="/" image={placeholder} />
				<ThumbnailLink to="/" image={placeholder} />
				<ThumbnailLink to="/" image={placeholder} />
				<ThumbnailLink to="/" image={placeholder} />
				<ThumbnailLink to="/" image={placeholder} />
			</div>
			
		</Layout>
	)
}

Styleguide.propTypes = {
  data: PropTypes.object.isRequired
}

export default Styleguide

export const StyleguidePageQuery = graphql`
  query StyleguideQuery {
    contentfulSettings(contentful_id: {eq: "4a97HzO48SL81JSXrVYDar"}) {
      defaultMetaTitle
			defaultMetaDescription {
				defaultMetaDescription
			}
			defaultShareImage {
				gatsbyImageData(
					layout: FULL_WIDTH
				)
			}
    }
  }
`